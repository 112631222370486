footer{
    background: #192A48;
    .social__items{
        padding-right: rem(135);
        @include bp-lt($medium){
            padding-right: rem(40);    
        }
        @include bp-lt($xmedium){
            padding-right: rem(0);    
        }
        li{
            display: inline-block;
            color: #fff;
            font-size: rem(15);
            &:first-child{
                padding-right: rem(17);
                @include bp-lt($xmedium){
                    padding-right: rem(5);
                }
            }
        }
        &--item{
            a{
                .fa{
                    border: 1px solid #fff;
                    color: #fff;
                }
               
            }
        }
    }
    .container{
        display: flex;
        justify-content: space-between;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        align-items: center;
        max-width: rem(1668);
        padding: rem(30) .9375rem rem(22);
        @include bp-lt($small){
            flex-direction: column;
        }
        .block-left{
            width: 60%;
            display: flex;
            align-items: center;
            @include bp-lt($medium){
                width: 70%;
            }
            @include bp-lt($small){
                width: 100%;
                flex-direction: column;    
            }
            .logo{
                img{
                    min-width: rem(206);
                    @include bp-gt($xmedium){
                        min-width: 9.375rem;
                        width: 9.375rem;
                    }
                }
            }
            ul{
                padding-left: rem(40);
                margin: 0;
                display: flex;
                @include bp-lt($medium){
                    padding-left: rem(20);    
                }
                @include bp-lt($small){
                    padding-left: rem(0);
                    margin: rem(20) 0;
                }
                li{
                    display: inline-block;
                    padding: 0 rem(30);
                    @include bp-lt($medium){
                        padding: 0 rem(20);    
                    }
                    @include bp-lt($small){
                        padding: 0 rem(6);    
                    }
                    a{
                        color: #fff;
                        &:after{
                            background: #fff;
                        }
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                }
            }
        }
        .block-right{
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include bp-lt($medium){
                width: 30%;    
            }
            @include bp-lt($small){
                width: 100%;
                justify-content: space-between;
            }
            .retour{
                color: #fff;
                font-size: rem(15);
                text-transform: uppercase;
            }
        }

    }
    .menu-footer{
        color: #fff;
    }

}
