.social__items{
            &--item{
                display: inline-block;
                padding: 0 rem(10) 0 0;
                margin: 0;
                &:before{
                    display: none;
                }
            a{
               
                .fa{
                    width: rem(36);
                    height: rem(36);
                    border: 1px solid #192a48;
                    border-radius: rem(36);
                    color: #192a48;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: rem(20);
                    transition: 0.3s all ease-in;
                }
                &:hover{
                    .fa-twitter{
                        color: #fff;
                        border: 1px solid $twitterColor;
                        background-color: $twitterColor;

                    }
                    .fa-linkedin{
                        color: #fff;
                        border: 1px solid #4875B4;
                        background-color: #4875B4;
                    }
                }
            } 
    }
}

main{
    background: #E6EFF2;
    padding: rem(267) 0 rem(142);
    @include bp-lt($small){
        padding: rem(200) 0 rem(142);
    }
}

.home{    
    main{
        padding: 8.875rem 0 0 0;
        .container{
            height: calc(100vh - 8.875rem);
            padding: 0px;
            max-width: 100%;
            .home-img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .text-home{ 
                max-width: rem(1920);
                padding-left: rem(50);
                padding-right: rem(50);
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                position: absolute;
                top: -1px;
                left: 0;
                right: 0;
                @include bp-lt($xxxlarge){
                    padding-left: rem(50);
                    padding-right: rem(15);
                }
                @include bp-lt($medium){
                    padding-left: rem(15);
                }
                @include bp-lt($mobileS){
                    padding-left: rem(10);
                }
                h2{
                    font-size: rem(470);
                    font-family: $Raleway-Black;
                    line-height: 0.717;
                    color: #fff;
                    display: inline-block;
                    position: relative;
                    @include bp-lt($xxlarge){
                        font-size: rem(400);
                    }
                    @include bp-lt(1150px){
                        font-size: rem(340);
                    }
                    @include bp-lt($xmedium){
                        font-size: rem(240);
                    }
                    @include bp-lt($mobileMO){
                        font-size: rem(200);
                    }
                    @include bp-lt($mobileS){
                        font-size: 12rem;
                    }
                    
                    span{
                        position: absolute;
                        font-size: rem(41);
                        top: rem(32);
                        right: 0;
                        font-family: $Raleway-Light;
                        text-transform: uppercase;
                        @include bp-lt($xxlarge){
                            font-size: rem(30);
                            top: rem(30);
                        }
                        @include bp-lt($xlarge){
                            font-size: rem(28);
                            top: rem(28);
                        }
                        @include bp-lt($xmedium){
                            font-size: rem(21);
                            top: rem(21);
                        }
                        @include bp-lt($mobileMO){
                            font-size: rem(18);
                            top: rem(18);
                        }
                    }
                }
            }
        }    
    }
}

.two--block{
    display: flex;
    justify-content: space-between;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    @include bp-lt($small){
        flex-direction: column;
        margin-bottom:rem(40) !important;
    }
    &.m-t-b{
        margin-bottom:rem(77) ;
    }
    &.margin-min{
        margin-bottom:rem(-115) ;
        z-index: 1;
        .two--block__small{
            padding-bottom: rem(125);
            @include bp-lt($small){
                padding-bottom: rem(30);    
            }
        }
    }
    &__small{
        width: rem(552);
        padding-right: rem(122);
        @include bp-lt($small){
            width: 100%;    
            padding-right: rem(0);
            margin-bottom: rem(30);
        }
        &--title{
            margin-bottom: rem(90);
        }

        &--desc{
            p{
                font-size: rem(35);
                line-height: 1.45;
                
                margin-bottom: 5.625rem;
                    @include bp-lt($small){
                        margin-bottom: 0;    
                    }
            }
        }
        
        .content-contact-left{
            .site{
                font-size: rem(20);
                line-height: 1.5;
                font-family: $Raleway-Bold;
            }   
            p{
                font-size: rem(20);
                line-height: 1.5;
                margin-bottom: rem(20);
                strong{
                    display: block;
                }
                span{
                    display: block;
                }
            }
        }
    }
    &.block-left{
        .two--block__large{   
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }   
    }
    &__large{
        width: rem(1082);
        @include bp-lt($small){
            width: 100%;
        }
        .large--content{
            background-color: #fff;
            padding: rem(40);
            @include bp-lt($small){
                padding: rem(20);    
            }
            &--title{
                font-size: rem(20);
                color:#37A3D9 ;
                display: flex;
                position: relative;
                align-items: center;
                padding-bottom: rem(38);
                padding-top: rem(14);
                margin-bottom: rem(10);
                text-transform: uppercase;
                font-family: $Raleway-Bold;
                a{color:#37A3D9;position: relative; z-index: 1;}
                &:before{
                    content: '';
                    width:rem(24) ;
                    height:rem(17) ;
                    display: inline-block;
                    background: url(../../../medias/images/arrow.svg);
                    background-size: cover;
                    margin-right: rem(16);
                }
                &:after{
                    content: '';
                    position: absolute;
                    max-width: rem(474);
                    width:60%;
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background:#b2cbd3 ;
                }
            }
            &--desc{
                .desc-wrapper{
                    h3{
                        margin: rem(35) 0 rem(28);
                    }
                    
                    p{
                        font-size: rem(20);
                        line-height: 1.5;
                    }
                }
            }
            &--footer{
                border-top: 1px solid#b2cbd3 ;
                margin-top: rem(25);
                padding-top: rem(38);
                .social__items{
                    margin-bottom: 0px;
                }
            }
            .block-dwonlaod{
                border-top: 1px solid#b2cbd3 ;
                margin-top: rem(20);
                padding-top: rem(38);
                padding-bottom: rem(13);
                p{
                    padding: 0;
                    line-height: normal;
                }
                .downlaod-link{
                    display: flex;
                    text-transform: uppercase;
                    color: #192A48;
                    font-size: rem(15);
                    align-items: center;
                    margin: 0;
                    display: inline-block;
                    vertical-align: middle;
                    padding: rem(14) 0 rem(14) rem(48);
                    position: relative;
                    @include bp-lt($mobileS){
                        font-size: 0.82rem;
                    }
                    &:before{
                        display: inline-block;
                        vertical-align: middle;
                        content: "";
                        width: rem(36);
                        height: rem(36);
                        position: absolute;
                        background: url(../../../medias/images/download.svg) no-repeat;
                        background-size: 100%;
                        border: none;
                        position: absolute;
                        left: 0;
                        top: 3px;
                        @include bp-lt($mobileMO){
                            top: 3px;    
                        }
                    }
                    &:after{
                        background: #192A48;
                        left: rem(40);
                    }
                    &:hover{
                        &:after{
                            width: calc(100% - 40px);
                        }
                    }
                }
                
            }
        }
        .content-contact-right{
            .date{
                display: block;
                margin-bottom: rem(38);
                font-size: rem(20);
                color: #192A48;
                line-height: 1.5;
            }
            .content-contact-item{
                margin-bottom: rem(40);
            }
            h2{
                margin-bottom: rem(24);
                font-size: rem(30);
            }
            p{
                font-size: rem(20);
                line-height: 1.5;
            }
        }
       
    }
}


.grid{
    margin: 0 - rem(15);
    &:after{
        content: '';
        display: block;
        clear: both;
    }
}

.block-full{
    width: 100% !important;
    display: flex;
    @include bp-lt($small){
        display: block;    
    }
    .block-small{
        max-width: rem(526);
        width: 33.33%;
        padding-right: rem(15);    
        @include bp-lt($small){
            width: 100%;    
            max-width: 100%;    
            padding-right: 0;
            padding-bottom: rem(30);    
        }
        &:last-child{
            padding-right: 0px;    
            padding-left: rem(15);    
            @include bp-lt($small){
                padding-left: rem(0);        
            }
        }
    }
    .block-large{
        max-width: rem(1082);
        padding-right: rem(15);    
        @include bp-lt($small){
            padding-right: rem(0);    
            padding-bottom: rem(30);    
        }
    }
}
.grid-sizer,
.grid-item {
    width: 33.33%; 
    @include bp-lt($small){
        width: 100%;    
    }
}
.grid-item{
    float: left;
    padding: rem(15) ;
    .wrapper{
        background: #fff;
    }
    .citation{
        font-size: rem(45);
        line-height: 1.5;
        font-family: $RozhaOne-Regular;
    }
    .grid-item-video{
        position: relative; 
        overflow: hidden;
        iframe{
            position: absolute; 
            top: 0; 
            left: 0; 
            width: 100%; 
            height: 100%; 
            border: 0;
        }
    }
    .grid-item-visuel{
        overflow: hidden;
        font-size: 0;
        position: relative;
        img{
            transition: 0.3s all ease-in;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &:hover{
            // img{
            //     transform: scale(1.02);
            // }          
        }
        strong,.strong{
            color: #fff;
            position: absolute;
            z-index: 9;
            font-size: rem(50);
            line-height: normal;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            @include bp-lt($medium){
                font-size: rem(30);
            }
            @include bp-lt($small){
                font-size: rem(40);
            }
            span{
                display: block;
            }     
        }
    }
    .grid-item-text{
        position: relative;
        margin-top: - rem(46);
        h2{
            padding: rem(23) rem(40);
            font-size: rem(25);
            line-height: 1.5;
            text-transform: uppercase;
            background: #fff;
            color: #37A3D9;
            display: inline-block;
            max-width: 90%;
            a{
                color: #37A3D9;
            }
        }
        .wrapper-text{
            padding: rem(10) rem(33) rem(30);
            @include bp-lt($xmedium){
                padding: rem(20);
            }
        }
        .small-text{
            font-size: rem(16);
            line-height: 1.5;
            color: #192A48;
            strong{
                font-family: Raleway-Bold;
                text-transform: uppercase;
            }
        }

    }
}
.block-large{
    width: 66.66%;
    @include bp-lt($small){
        width: 100%;    
    }
    .grid-item-text{
        margin-top: - rem(48);
        @include bp-lt($medium){
            margin-top: - 1.5rem;
        }
    }
}

.block-img{
    .wrapper{
        padding: rem(60) 0;
        background-color: transparent;
    }
    h2{
        font-size: rem(25);
        line-height: 1.5;
        text-transform: uppercase;
        color: #37A3D9;
        text-align: center;
        margin: rem(30) 0;
    }
    p{
        
    }
}

.block-downlaod{
    .wrapper{
        background: #37A3D9; 
    }
    .grid-item-text{
        h2{
            background: #37A3D9; 
            color: #fff;   
        }
        .wrapper-text{
            background: #37A3D9; 
        }
    }
}

.block-small{
    .grid-item-text{
        h2{
            padding: rem(15) rem(42);
        }
        .wrapper-text{
            padding: rem(20) rem(50) rem(30);
            @include bp-lt($xmedium){
                padding: rem(20);
            }
        }
    }
}
.masonry-footer{
    margin: rem(15) 0 0 0;
    padding-top: rem(37);
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #b2cbd3;
    &.masonry-downlaod{
        justify-content: flex-end;
    }
    .social__items{
        margin: 0;
    }
    
    .masonry-icon{
        font-size: rem(15);
        display: flex;
        align-items: center;
        text-transform: uppercase;
        &:after{
            content: "";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            width: rem(36);
            height: rem(36);   
            border: 1px solid #192a48;
            border-radius: 2.25rem;
            color: #192a48;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: rem(14);
            font-size: rem(18);
            transition: 0.3s all ease-in;
            background-color: #fff;
        }
        &:hover{
            &:after{
                color: #fff;
                border: 1px solid #37A3D9;
                background-color: #37A3D9;
            }
        }
    }
    .read-more{
        &:after{
            content: "\f067";
        }
    }
    .download-icon{
        color: #fff;
        &:after{
            background: url(../../../medias/images/download-white.svg) no-repeat;
            background-size: cover;
            border: 1px solid transparent;
        }
        &:hover{
            &:after{
                background:#fff url(../../../medias/images/download-white-h.svg) no-repeat;
                background-size: cover;
                border: 1px solid transparent;
            }
        }
    }
}

.block-arround{
    text-align: center;
    h2{
        color: #37A3D9;
        font-size: rem(25);
        line-height: 1.5;
        text-transform: uppercase;
    }
    .wrapper {
        background: transparent;
        @include bp-gt($large_s){
            /*padding-bottom: rem(70); */
        }
    }
    .block-arround-text{
        padding: 0 rem(62) rem(10);
    }
    .arround-text-small{    
        font-size: rem(18);
        strong{
            display: block;
            text-transform: uppercase;
            margin-bottom: rem(12);
            font-size: rem(18);
        }
    }
    .arround-item{
        margin: rem(50) 0 rem(40);
        border-radius: 50%;
        &.arround-padd{
            padding: rem(80) rem(45);
        }
        @include bp-lt($medium){
            padding: 3rem 2.25rem;
        }
        p{
            font-size: rem(40);
            font-family: $RozhaOne-Regular;
            line-height: 1.23;
            padding: 0;
            @include bp-lt($medium){
                font-size: rem(32);
            }
            @include bp-lt($xmedium){
                font-size: 1.7rem;
            }
            @include bp-lt($small){
                font-size: 2.2rem;
            }
        }
        &.arround-item-big{
            p{
                font-size: rem(45);
                font-family: $RozhaOne-Regular;
                @include bp-lt($medium){
                    font-size: rem(40);
                }
                @include bp-lt($xmedium){
                    font-size: 1.7rem;
                }
                @include bp-lt($small){
                    font-size: 2.5rem;
                }
            }   
        }
    }
}

.block-number{
    text-align: center;
    .wrapper{
        background: transparent;
        padding: rem(70) 0;
        @include bp-lt($medium){
            padding: rem(70) 0 ;    
        }
    }
    h2{
        color: #37A3D9;
        font-size: rem(30);
        line-height: 1.5;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        padding-bottom: rem(42);
        margin-bottom: rem(42);
        &:after{
            content: '';
            width: rem(260);
            height: 2px;
            background: #192a48;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .number-item{
        font-size: rem(130);
        line-height: normal;
        font-family: $RozhaOne-Regular;
        color: #192A48;
        @include bp-lt($medium){
            font-size: rem(80);
        }
    }
}

.page-template-tple_contact {
    .two--block__large{
        .content-contact-right{
            p:first-of-type{
                display: block;
                margin-bottom: rem(38);
                font-size: rem(20);
                color: #192A48;
                padding: 0;
                line-height: 1.5;   
            }
        }
    }
}
