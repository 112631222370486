/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

*, *:before, *:after {
  @include box-sizing();
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    // vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
    height: 100%;
}
html{
    height: 100%;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

ol ol, ol ul, ul ol, ul ul {
    margin-left: 0;
}

.c-contenu{
    .two--block__large{
        width: 100%;
    }
}

body{
    width: 100%;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    main{
        -webkit-flex-grow: 1;
        flex-grow: 1;
        position: relative;
    }
}

.page-template-tple_contact main{
    padding-bottom: 0;
}

.c-contenu__article{
    .two--block__large{
        position:relative;
    }

    .two--block.block-left .two--block__large img{
        position: absolute;
        height: auto !important;
        width: 100%!important;
        max-width: 1000px;
        background-color: #fff;
        right: 0;
        bottom: 0;
        -o-object-fit: inherit !important;
        object-fit: inherit !important;
        @include bp-lt($small){
            position: relative;
        }
    }

    .two--block.margin-min .two--block__small {
        min-height: 600px;
        @include bp-lt($medium){
            min-height: 500px;
        }
        @include bp-lt($xmedium){
            min-height: 400px;
        }
        @include bp-lt($small){
            min-height: inherit;
        }
    }
}
.grid-item-video{
    .video-popup{
        content: "";   
	    background-image: url("#{$image}/icon-play.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 135px;
        height:135px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        cursor: pointer;
        &:hover{
            opacity: 0.8;
        }
    }
}