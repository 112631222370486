// ========================================= //
// fonts
// ========================================= //

// Raleway

@include font-face('Raleway-Black', 'Raleway-Black');
@include font-face('Raleway-Bold', 'Raleway-Bold');
@include font-face('Raleway-Regular', 'Raleway-Regular');
@include font-face('Raleway-Light', 'Raleway-Light');
@include font-face('RozhaOne-Regular', 'RozhaOne-Regular');
@include font-face('fontawesome-webfont', 'FontAwesome');





body{
    font-family: $Raleway-Regular;
    background: #fff;  
}
html{
    font-size: 16px;
    @include bp-lt($xxxlarge){
        font-size: 15px;
    }
    @include bp-lt($large){
        font-size: 14px;
    }
    @include bp-lt($large_s){
        font-size: 12px;
    }
    
}
li{
    list-style: none;
}
a{
    text-decoration: none;
    transition: 0.3s color ease-in;
    outline: none;
    cursor: pointer;  
    display: inline-block;
    color: #192A48;  
    &:hover{  
        color: #37A3D9 ;  
    }
}
.text-center{
    text-align:center;
}
img {
    max-width: 100%;
    height: auto;
}

h1, h2, h3, h4, h5, h5{
    font-family: $Raleway-Black;
    line-height: 1.13;
    color: #192a48;
}

h1 {
    color: #192A48;
    font-size: rem(80);
    line-height: 1.2;
}
h2 {
    font-size: rem(32);
    @media (max-width: 767.98px) {
        font-size: rem(26);
    }
}
.full-width{
    width:100% !important;
}
h3 {
    font-size: rem(30);
}
h4 {
    font-size: rem(22);
    
}
h5 {
    font-size: rem(20);
    margin-bottom: rem(5);
}
h6 {
    font-size: rem(18);
    margin-bottom: rem(5);
}
a{
    text-decoration: none;
    &:hover{
        text-decoration:none;
    }
}
p {
    padding-bottom: rem(10);
    font-size:rem(20);
    line-height: 1.5;
    color: #192A48;
    font-family: $Raleway-Regular;
}
strong {
    font-family: $Raleway-Black;
}
.container-fluid{
    padding-right: rem(50);
    padding-left: rem(50);
    @include bp-lt($desktopL){
        padding-right: rem(15);
        padding-left: rem(15);
    }
}

.effect-link a{
    position: relative;
    padding: rem(5) 0;
    &:after{
        position: absolute;
        content: "";
        width: 0px;
        transition: 0.3s all ease-in;
        height: 1px;
        left: 0px;
        bottom: 0;
        background-color: #192a48;
    }
    &:hover{
        &:after{
            width: 100%;
        }
    }
}



main{
.puce, ul{
    margin-bottom: rem(35);
    li{
        position: relative;
        padding-left: rem(20);
        margin-bottom: rem(10);
        font-size: rem(20);
        line-height: 1.5;
        &:before{
            content: '';
            position: absolute;
            width:rem(7);
            height: rem(7);
            bottom: 0;
            left: 0;
            top: rem(12);
            border-radius: rem(7);
            background:#37A3D9 ;
        }
    }
    ul{
        margin-bottom: 0px;
        li{
            padding-left: rem(12);
            margin-bottom: 0px;
            margin-top: rem(10);
            &:before{
                width:rem(5);
                background:#192a48;
                height: rem(4);
            }
        }
    }
}
}
.m-t-5{
    margin-top: rem(50);
}

.title-page{
    font-size: rem(120);
    line-height: 1;
    margin-bottom: rem(100);
    @include bp-lt($medium){
        font-size: rem(80);
        margin-bottom: rem(80);
    }
    span{
        display: block;
        font-family: $Raleway-Light;
    }
}

.wrapper-img{
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-size: 0;
    img{
        transition: 0.3s all ease-in;
    }
    // &:hover{
    //     img{
    //         transform: scale(1.02);
    //     }          
    // }
}

.bg-white{
    background-color: #fff;
    p{
        color: #192A48;
    }
}
.bg-bleu{
    background-color: #192A48;
    p{
        color: #fff;
    }
}

@include bp-gt($medium){
    a[href^="tel:"] {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
    }
}