// ========================================= //
// header
// ========================================= //
header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0px;
    background: url(../../../medias/images/bg-header.gif) no-repeat center;
    z-index: 9;
    transition: .2s all ease-out;
    @include bp-lt($largeDesktop){
        background-size: cover;
    }
    @include bp-lt($small){
        background: url(../../../medias/images/bg-header-mobile.png) no-repeat center;        
        background-size: cover;
    }
    &.sticker{
        .container{
            height: rem(90);
        }
        .logo{

            img{
                min-width: rem(150);
                width: rem(150);

            }
        }    
    }
    .container{
        height: rem(142);
        transition: 0.3s all ease-in;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: rem(50);
        padding-right: rem(50);
        
        @include bp-lt($medium){
            padding-left: rem(15);
            padding-right: rem(15);
        }
        @include bp-lt($xlarge ){
            height: rem(100);
        }
    }

    .logo{
        img{
            min-width: rem(249);
            width: rem(249);
            transition: 0.3s all ease-in;
            @include bp-lt($small){
                min-width: rem(180);
                width: rem(180);
            }
        }
    }
    .header-right{
        display: flex;
        align-items: center;
        .slogan{
            font-size: rem(20);
            font-family: $Raleway-Light;
            padding: 0 rem(47)  0 0;
            @include bp-lt($large_s){
                padding: 0 rem(10)  0 0;
            }
            @include bp-lt($small){
                display: none;
            }
        }
    }
    .burger{
        width: rem(35);
        height: rem(35);
        background: url(../../../medias/images/buger.svg);
        background-size: cover;
        transition: 0.3s all ease-in;
        cursor: pointer;
        &.active{
            background: url(../../../medias/images/buger-active.svg);    
            background-size: cover;
        }
    }
}

.wrapper-site-menu{
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0;
    left: 0;
    z-index: 9;
    transition: .5s transform ease-out;
    transform: translateX(200%);
    will-change: transform;
    .site-menu{
        position: absolute;
        top: 0px;
        bottom: 0px;
        z-index: 99;
        right: 0;
        background: #fff;
        width: rem(800);
        padding-top: rem(155);
        @include bp-lt($small){
            width: 100%;    
            overflow: auto;
            padding-top: 6.6875rem;
        }
        .burger.active{
            background: url(../../../medias/images/buger-active.svg);    
            background-size: cover;
            width: rem(35);
            height: rem(35);
            position: absolute;
            right: rem(53);
            top: rem(53);
            cursor: pointer;
            transition: 0.3s all ease-in;
            @include bp-lt($xxxlarge){
                right: rem(15);    
            }
        }
        ul{
            margin: 0;
            li{
                margin: 0;
                padding: rem(33) rem(108);
                @include bp-lt($small){
                    padding: rem(20);
                }
                &:before{
                    display: none;
                }
                a{
                    font-size: rem(47);
                    font-family: $Raleway-Light;
                    line-height: normal;
                    @include bp-lt($small){
                        font-size: rem(40);
                    }
                    strong{
                        font-family: $Raleway-Bold;
                    }
                    span{
                        display: block;
                    }
                }
            }
        }
    }    
}
.wrapper-menu-home{
    max-width: 120rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0;
    left: 0;
    z-index: 9;
    transition: .5s transform ease-out;
    transform: translateX(200%);
    will-change: transform;
    .burger.active{
        background: url(../../../medias/images/buger-active.svg);    
        background-size: cover;
        width: rem(35);
        height: rem(35);
        position: absolute;
        right: rem(50);
        top: 3.3rem;
        cursor: pointer;
        transition: 0.3s all ease-in;
        @include bp-lt($medium){
            right: rem(15);    
        }
    }
    .menu-home{
        position: absolute;
        top: 0px;
        bottom: 0px;
        z-index: 99;
        padding: 0;
        width: auto;
        right: rem(150);    
        @include bp-lt($medium){
            right: rem(70);    
        }
        ul{
            height: 100vh;
            margin: 0;
            li{
                margin: 0;
                height: 33.33vh;
                width: rem(665);
                background: rgb(55,87,136);
                background: linear-gradient(90deg, rgba(55,87,136,1) 0%, rgba(16,48,105,1) 100%);
                padding: rem(40) rem(40) rem(40) rem(48);
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                position: relative;
                @include bp-lt($xxlarge){
                    width: rem(600);
                }
                @include bp-lt($xlarge){
                    width: rem(500);
                    padding: rem(30) rem(30) rem(30) rem(38);
                }
                @include bp-lt($medium){
                    width: rem(400);
                    padding: rem(20) rem(20) rem(20) rem(28);
                }
                @include bp-lt($mobileS){
                    width: rem(320);
                }
                &:before{
                    display: none;
                }
                &:after{
                    content: "";
                    top: 0;
                    left: 0px;
                    position: absolute;
                    width: 0;
                    height: 100%;
                    transition: .2s all ease-out;
                }
               &:nth-child(2) {
                background: rgba(29, 122, 165, 0.3);
               }
               &:nth-child(3) {
                background: rgba(24, 181, 198, 0.3);
               }
               &:hover{
                    &:after{
                        width: 100%;
                        background: #fff;
                    }
                   a{
                       color: #37A3D9;
                   }
               }
               a{
                z-index: 1;
                color: #fff;
                font-size: rem(47);
                font-family: $Raleway-Light;
                line-height: normal;
                &:before{
                    content: "";
                    top: 0;
                    left: 0px;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
                @include bp-lt($xlarge){
                    font-size: rem(35);    
                }
                strong{
                    font-family: $Raleway-Bold;
                }
                span{
                    display: block;
                }
               }
            }
        }
    }
}
.home{
    header{
        background: #fff;
        .logo{
            h1{
                line-height: 0;
            }
        }
        .container{
            height: rem(142);
            @include bp-lt($xlarge ){
                height: rem(142);
            }   
        }
    }
    main .container, header{
        filter: initial !important;
    }
    &.menu-active{
        header{
            .header-right{
                width: rem(1070);
                justify-content: space-between;
                @include bp-lt($xxlarge){
                    width: rem(1090);    
                }
                @include bp-lt($xlarge){
                    width: rem(960);    
                }
                @include bp-lt($medium){
                    width: rem(750);    
                }
                @include bp-lt($xmedium){
                    width: rem(730);    
                }
            }
            
        }
    }
}

body.menu-active{
    overflow: hidden;
    .wrapper-site-menu{
        transform: translateX(0);
    }
    main .container, header, footer .container{
        filter: blur(10px);
    }
    header{
        .burger{
            opacity: 0;
            visibility: hidden;
        }
    }
    .wrapper-menu-home{
        transform: translateX(0);
    }
}

// ========================================= //
// Fin header
// ========================================= //