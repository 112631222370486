.container {
	max-width: rem(1920);
	padding-left: rem(15);
	padding-right: rem(15);
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	position: relative;
}
main{
	.container {
		transition: .2s all ease-out;
		max-width: rem(1668);
	}
}