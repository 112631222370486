// ========================================= //
// colors
// ========================================= //


$white: #ffffff !default;
$white--02: #f5f5f5 !default;

$black: #000000 !default;
$black-02: #313131 !default;


$success: #2ecc71 !default;
$error: #e74c3c !default;
$yellow: #ffce32 !default;





$gray: #565656 !default;
$gray--02: #979797 !default;

$gray--03: #cfcfcf !default;

$gray--04: #dadada !default;

$gray--05: #d0d5db !default;


$midnight--02: #1a3853 !default;

$midnight--03: #183651 !default;


$borderColor: $gray--02;


$border: 1px solid $borderColor;


$facebookColor: #3B5998 !default;
$twitterColor: #00ACED !default;
$instagramColor: #BC2A8D !default;
$youtubeColor: #BB0000 !default;
$snapchatColor: #fffc00 !default;